// @ts-ignore
import * as ELG from 'esri-leaflet-geocoder';
import { devZones, prodZones } from '../consts/available-zones';
import { Address } from '../shared/models/Address';
import { Position } from '../shared/models/Position';
import { Entries } from '../shared/types/Entries';

const reverseGeocodingProvider = new ELG.ReverseGeocode();
const geocodingProvider = new ELG.Geocode();

function reverseGeocode(position: Position) {
  return new Promise<Address | null>((resolve, reject) => {
    reverseGeocodingProvider
      .latlng(position)
      .run((error: unknown, position: { address: Address } | undefined) => {
        if (error) {
          reject('Can not geocode current coordinates');
        }

        resolve(position?.address || null);
      });
  });
}

function geocode(text: string) {
  return new Promise<any>((resolve, reject) => {
    geocodingProvider
      .text(text)
      .run(
        (
          error: unknown,
          results: { latlng: Position; properties: Address }[]
        ) => {
          if (error) {
            reject('Can not geocode place that you are looking for');
          }

          resolve(results);
        }
      );
  });
}

function getActualZones() {
  if (Boolean(process.env.REACT_APP_TEST_ENV)) {
    return devZones;
  } else {
    return prodZones;
  }
}

function isRegionSupported(address: Address) {
  const actualZones = getActualZones();

  const matchedZone = actualZones.find((zone) => {
    const addressKeValueParts = Object.entries(zone) as Entries<Address>;

    return addressKeValueParts.every(([key, value]) => address[key] === value);
  });

  return !!matchedZone;
}

export { geocode, reverseGeocode, isRegionSupported };
