import { Address } from '../shared/models/Address';

export const devZones: Partial<Address>[] = [
  {
    CountryCode: 'POL',
  },
  {
    CountryCode: 'USA',
  },
];

export const prodZones: Partial<Address>[] = [
  {
    CountryCode: 'USA',
    Region: 'New Mexico',
    City: 'Santa Fe',
  },
  {
    CountryCode: 'USA',
    Region: 'California',
    City: 'San Francisco',
  },
  {
    CountryCode: 'USA',
    Region: 'New Mexico',
    City: 'Albuquerque',
  },
  {
    CountryCode: 'USA',
    Region: 'New Mexico',
    City: 'Bernalillo',
  },
  {
    CountryCode: 'USA',
    Region: 'New Mexico',
    Subregion: 'Bernalillo County',
  },
  {
    CountryCode: 'USA',
    Region: 'Virginia',
  },
  {
    CountryCode: 'USA',
    Region: 'District of Columbia',
  },
];
