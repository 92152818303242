import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Address } from '../shared/models/Address';
import { Phone } from '../shared/models/Phone';
import { Position } from '../shared/models/Position';
import { WeaponInvolvedStatus } from '../shared/models/WeaponInvolved';
import CallStatus from '../shared/types/CallStatus';
import Emergency from '../shared/types/Emergency';
import EmergencyDictionary from '../shared/types/EmergencyDictionary';
import ActiveEmergency from '../shared/types/ActiveEmergencies';
import ActiveEmergencyDictionary from '../shared/types/ActiveEmergencyDictionary';
import { LocationType } from '../shared/types/LocationType';

type AlarmRecipient = 'me' | 'someone' | 'both' | null;
type UrgencyResponse = 'yes' | 'no' | 'not sure' | null;
export interface SliceState {
  testState: boolean;
  phone: Phone;
  position: Position;
  searched: boolean;
  name: {
    firstname: string;
    lastname: string;
  };
  address: Address | null;
  mentalHealth: {
    note: string;
    yes: boolean;
    no: boolean;
  };
  emergencies: EmergencyDictionary;
  lifeThreatening: UrgencyResponse;
  accommodations: {
    selected: boolean[];
    note: string;
  },
  activeEmergencies: ActiveEmergencyDictionary;
  weaponInvolved: WeaponInvolvedStatus | undefined;
  userDetails: {
    name: string;
    more: string;
    alarmForSomeone: AlarmRecipient;
  };
  editableInfo: string;
  emergencyDetails: {
    selected: string[];
    isNoneOptionSelected: boolean;
    additionalInfo: string;
  };
  location: {
    type: LocationType;
    details: {
      roomNumber: string;
      floorNumber: string;
      outdoorsDetails: string;
      busNumber: string;
      exitNumber: string;
    };
  };
  isSummarized: boolean;
  callStatus: CallStatus;
  isGeolocationEnabled?: boolean;
  retries: number;
  currentStep: number;
}

export const initialState: SliceState = {
  testState: false,
  phone: {
    number: '',
    dialCode: '',
    countryCode: 'us',
  },
  position: {
    lat: 0,
    lng: 0,
  },
  searched: false,
  name: {
    firstname: '',
    lastname: '',
  },
  address: null,
  mentalHealth: {
    note: '',
    yes: false,
    no: false
  },
  lifeThreatening: null,
  accommodations: {
    selected: [false, false, false, false],
    note: '',
  },
  emergencies: {
    fire: false,
    medical: false,
    police: false,
  },
  activeEmergencies: {
    fire: false,
    medical: false,
    mental: false,
    police: false
  },
  weaponInvolved: undefined,
  userDetails: {
    name: '',
    more: '',
    alarmForSomeone: null,
  },
  editableInfo: '',
  emergencyDetails: {
    selected: [],
    isNoneOptionSelected: false,
    additionalInfo: '',
  },
  location: {
    type: null,
    details: {
      roomNumber: '',
      floorNumber: '',
      outdoorsDetails: '',
      busNumber: '',
      exitNumber: '',
    },
  },
  isSummarized: false,
  callStatus: 'idle',
  retries: 0,
  currentStep: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTestState(state, action: PayloadAction<{update: boolean}>) {
      state.testState = action.payload.update;
    },
    resetRetries(state) { state.retries = 0 },
    incrementRetries(state) { state.retries++; },
    setStep(state, action: PayloadAction<{ step: number }>) 
    { state.currentStep = action.payload.step; },
    addPhone(state, action: PayloadAction<{ phone: Phone }>) {
      state.phone = action.payload.phone;
    },
    addLocation(
      state,
      action: PayloadAction<{ position: Position; address: Address | null }>
    ) {
      const { position, address } = action.payload;
      state.position = position;
      state.address = address;
    },
    setAccommodations(
      state,
      action: PayloadAction<{ type: number, selected:  boolean }>
    ){
      const { type, selected } = action.payload;
      switch(type) {
        case (1): 
          if(selected && state.accommodations.selected[3]) {
            state.accommodations.selected[3] = false;
          }
          state.accommodations.selected[0] = selected;
          break;
        case (2): 
          if(selected && state.accommodations.selected[3]) {
            state.accommodations.selected[3] = false;
          }
          state.accommodations.selected[1] = selected;
          break;
        case (3): 
          if(selected && state.accommodations.selected[3]) {
            state.accommodations.selected[3] = false;
          }
          state.accommodations.selected[2] = selected;
          break;
        case (4): 
          if(selected) {
            state.accommodations.selected[0] = false;
            state.accommodations.selected[1] = false;
            state.accommodations.selected[2] = false;
          }
          state.accommodations.selected[3] = selected;
          break;
        default:
          break;
      }
    },
    setUrgencyResponse(
      state,
      action: PayloadAction<{ response: UrgencyResponse }>
    ) {
      const { response } = action.payload;
      state.lifeThreatening = response;
    },
    changeEmergencyStatus(
      state,
      action: PayloadAction<{ emergency: Emergency; status: boolean }>
    ) {
      const { emergency, status } = action.payload;
      state.emergencies[emergency] = status;
    },
    setActiveEmergency(
      state,
      action: PayloadAction<{emergency: ActiveEmergency; active: boolean}>
    ) {
      const { emergency, active } = action.payload;
      state.activeEmergencies[emergency] = active;
      if(emergency === 'mental') {
        if(active) {
          state.mentalHealth.note = 'User is having a mental health crisis' 
        }
        else {
          state.mentalHealth.note = ''
        }
      } 
    },
    setYes(
      state, action: PayloadAction<{status: boolean}>
    ) {
      const { status } = action.payload;
      state.mentalHealth.yes = status;
      state.mentalHealth.no = !status;
    },
    setNo(
      state, action: PayloadAction<{status: boolean}>
    ) {
      const { status } = action.payload;
      state.mentalHealth.no = status;
      state.mentalHealth.yes = !status;
    },
    setWeaponInvolved(
      state,
      action: PayloadAction<{
        weaponInvolved: WeaponInvolvedStatus | undefined;
      }>
    ) {
      state.weaponInvolved = action.payload.weaponInvolved;
    },
    addEmergencyDetail(
      state,
      action: PayloadAction<{ emergencyDetailName: string }>
    ) {
      state.emergencyDetails.isNoneOptionSelected = false;
      state.emergencyDetails.selected.push(action.payload.emergencyDetailName);
    },
    removeEmergencyDetail(
      state,
      action: PayloadAction<{ emergencyDetailName: string }>
    ) {
      state.emergencyDetails.selected = state.emergencyDetails.selected.filter(
        (emergency) => emergency !== action.payload.emergencyDetailName
      );
    },
    setNoneEmergencyDetailSelected(state, action: PayloadAction<{ newState: boolean }>) {
      state.editableInfo = '';
      state.emergencyDetails.selected = [];
      state.emergencyDetails.isNoneOptionSelected = action.payload.newState;
    },
    setEditableDetail(state, action: PayloadAction<{ value: string }>) {
      state.editableInfo = action.payload.value;
    },
    addAccommodations(state) {
      var accTypes = "";
      if(state.accommodations.selected[0]) {
        accTypes += "'Interpreter'";
      }
      if(state.accommodations.selected[1]) {
        if(state.accommodations.selected[0]) {
          accTypes += ", "
        }
        accTypes += "'Live Captioning"
      }
      if(state.accommodations.selected[2]) {
        if(state.accommodations.selected[1] || 
           state.accommodations.selected[0]){
          accTypes += ", "
        }
        accTypes += "'Support Service Provider'"
      }
      if(!state.accommodations.selected[3] && accTypes) {
        state.accommodations.note = "User requests: " + accTypes;
      } else {
        state.accommodations.note = "";
      }
    },
    setAdditionalEmergencyDetails(state) {
      var fullDetails = "";
      if(state.mentalHealth.note) {
        fullDetails += state.mentalHealth.note;
      } 
      if(state.accommodations.note) {
        if(state.mentalHealth.note) {
          fullDetails += " | "
        }
        fullDetails += state.accommodations.note;
      }
      if(state.editableInfo) {
        if(state.mentalHealth.note || state.accommodations.note) {
          fullDetails += " | "
        }
        fullDetails += state.editableInfo;
      }
      state.emergencyDetails.additionalInfo = fullDetails;
    },
    setLocation(state, action: PayloadAction<{ location: LocationType }>) {
      state.location.details = initialState.location.details;
      state.location.type =
        state.location.type === action.payload.location
          ? null
          : action.payload.location;
    },
    setRoomNumber(state, action: PayloadAction<{ value: string }>) {
      state.location.details.roomNumber = action.payload.value;
    },
    setFloorNumber(state, action: PayloadAction<{ value: string }>) {
      state.location.details.floorNumber = action.payload.value;
    },
    setOutdoorsDetails(state, action: PayloadAction<{ value: string }>) {
      state.location.details.outdoorsDetails = action.payload.value;
    },
    setBusNumber(state, action: PayloadAction<{ value: string }>) {
      state.location.details.busNumber = action.payload.value;
    },
    setExitNumber(state, action: PayloadAction<{ value: string }>) {
      state.location.details.exitNumber = action.payload.value;
    },
    setCallStatus(state, action: PayloadAction<{ status: CallStatus }>) {
      state.callStatus = action.payload.status;
    },
    setPersonalName(state, action: PayloadAction<{ name: string }>) {
      state.userDetails.name = action.payload.name;
    },
    setPersonalDetails(state, action: PayloadAction<{ details: string }>) {
      state.userDetails.more = action.payload.details;
    },
    setFirstname(state, action: PayloadAction<{ firstname: string }>) {
      state.name.firstname = action.payload.firstname.trim();
      state.userDetails.name = `${state.name.firstname} ${state.name.lastname}`;
    },
    setLastname(state, action: PayloadAction<{ lastname: string }>) {
      state.name.lastname = action.payload.lastname.trim();
      state.userDetails.name = `${state.name.firstname} ${state.name.lastname}`;
    },
    setRecipient(
      state,
      action: PayloadAction<{ alarmForSomeone: AlarmRecipient }>
    ) {
      state.userDetails.alarmForSomeone = action.payload.alarmForSomeone;
      state.userDetails.name =
        action.payload.alarmForSomeone !== 'me'
          ? ''
          : `${state.name.firstname} ${state.name.lastname}`.trim();
    },
    summarize(state) {
      state.isSummarized = true;
    },
    reset(state) {
      return { ...initialState, phone: state.phone, name: state.name };
    },
    setGeolocationIsEnabledStatus(state, action: PayloadAction<boolean>) {
      return { ...state, isGeolocationEnabled: action.payload };
    },
  },
});

const { actions, reducer } = appSlice;

export const {
  setTestState,
  resetRetries,
  incrementRetries,
  setStep,
  addPhone,
  addLocation,
  setAccommodations,
  setUrgencyResponse,
  changeEmergencyStatus,
  addAccommodations,
  addEmergencyDetail,
  setAdditionalEmergencyDetails,
  removeEmergencyDetail,
  setCallStatus,
  summarize,
  reset,
  setActiveEmergency,
  setYes,
  setNo,
  setWeaponInvolved,
  setNoneEmergencyDetailSelected,
  setLocation,
  setRoomNumber,
  setFloorNumber,
  setOutdoorsDetails,
  setBusNumber,
  setExitNumber,
  setPersonalName,
  setPersonalDetails,
  setEditableDetail,
  setFirstname,
  setLastname,
  setRecipient,
  setGeolocationIsEnabledStatus,
} = actions;

export default reducer;
